import { HTTP } from '@/App/Utils/axios'
import { GeofenceItem, StoreGeofence } from './interfaces/geofence.interface'

export class GeofenceService {
  static async fetch(): Promise<GeofenceItem[]> {
    try {
      const { data } = await HTTP.get('/geofences/')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(geofence: StoreGeofence): Promise<GeofenceItem> {
    try {
      const { data } = await HTTP.post('/geofences/', geofence)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(geofenceId: string, geofence: Partial<StoreGeofence>): Promise<GeofenceItem> {
    try {
      const { data } = await HTTP.patch(`/geofences/${geofenceId}`, geofence)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(geofenceId: string): Promise<void> {
    try {
      await HTTP.delete(`/geofences/${geofenceId}`)
    } catch (e) {
      throw e
    }
  }

  static async import(file: string): Promise<void> {
    try {
      await HTTP.post('/geofences/import', { file })
    } catch (e) {
      throw e
    }
  }
}
